import { GoArrowRight, GoChevronLeft } from "react-icons/go";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { nextStep, previousStep } from "@/store/features/wizardSlice";
import { twMerge } from "tailwind-merge";
import { useRef } from "react";
import dynamic from "next/dynamic";

const CardCapture = dynamic(() => import("@/components/CardCapture"));
const ComplianceVideo = dynamic(() => import("@/components/ComplianceVideo"));
const DocumentSigning = dynamic(() => import("@/components/DocumentSigning"));
const ThankYou = dynamic(() => import("@/components/ThankYou"));
const Welcome = dynamic(() => import("@/components/Welcome"));

const getWizardStep = ({
  step,
  continueHook,
  steps,
}: {
  step: number;
  steps: any;
  continueHook: any;
}) => {
  const {
    text,
    pandadoc,
    vimeo,
    secondaryOnboardingUrl,
    oncehubDocId,
    // primaryOnboardingUrl,
    orderformproduct,
  } = steps[step]; // different steps have different values
  // console.log("wizard incoming steps", steps);
  // console.log("step params for step", step);
  // console.log("param text", text); // welcome
  // console.log("param video", vimeo);
  // console.log("param pandadoc", pandadoc);
  // console.log("param orderformproduct", orderformproduct);
  // console.log("param orientationurl", orientationurl);

  // v2: get component type from step content
  // has text? = welcome page with text
  // has pandadoc? = <DocumentSigning eForm=
  // has vimeo? = <ComplianceVideo vimeo=
  // has orderformproduct? = <CardCapture product=orderformproduct
  // has orientationurl? = <ThankYou url=orientationurl
  if (text) {
    // console.log("param text, loading Welcome", text); // welcome
    return <Welcome text={text} stepNumber={step} />;
    // console.log("param video, loading ComplianceVideo", vimeo);
  }
  if (vimeo) {
    return <ComplianceVideo vimeo={vimeo} stepNumber={step} />;
  }
  if (pandadoc) {
    // console.log("param pandadoc, loading DocumentSigning", pandadoc);
    return <DocumentSigning eForm={pandadoc} stepNumber={step} />;
  }
  if (orderformproduct) {
    // console.log("param orderformproduct, loading CardCapture", orderformproduct);
    return (
      <CardCapture
        orderformproduct={orderformproduct}
        continueHook={continueHook}
        stepNumber={step}
      />
    );
  }
  if (oncehubDocId) {
    return (
      <ThankYou
        oncehubDocId={oncehubDocId}
        // primaryOnboardingUrl={primaryOnboardingUrl}
        secondaryOnboardingUrl={secondaryOnboardingUrl}
      />
    );
  }

  // FALLBACK to STEP logic
  console.log("FALLING BACK to step numbers:", step);
  switch (step) {
    case 1:
      return <Welcome text={"default"} stepNumber={1} />;
    case 2:
      return <ComplianceVideo vimeo={vimeo} stepNumber={2} />;
    case 3:
      return <DocumentSigning eForm={pandadoc} stepNumber={3} />;
    case 4:
      return (
        <CardCapture
          continueHook={continueHook}
          orderformproduct={12345}
          stepNumber={4}
        />
      );
    case 5:
      return <ThankYou />;
  }
};

export default function Wizard({
  // eslint-disable-next-line no-unused-vars
  user,
}: {
  user: { firstName: string; lastName: string; email: string };
}) {
  const dispatch = useAppDispatch();

  const wizardState = useAppSelector((state) => state.wizard);
  const {
    steps,
    step: currentStep,
    totalSteps: numberSteps,
    canGoBack,
    loading,
  } = wizardState;

  // console.log("wizstate steps", steps);

  const continueHook: any = useRef();

  const onNext = async () => {
    try {
      // tap into addcard here on step 4
      if (currentStep === 4) {
        // TODO don't hardcode steps
        if (continueHook.current) {
          console.log("Run child hook before Continue");
          await continueHook.current(); // COULD throw if something bad happens in child pre-Continue-hook
        }
      }

      // clear any hooks
      continueHook.current = null;

      if (currentStep === numberSteps) {
        // no more steps here
        return;
      }
      dispatch(nextStep());
    } catch (err) {
      console.log("error in onNext", err);
    }
  };
  const onPrevious = () => {
    if (currentStep === 1 || !canGoBack) {
      return;
    }
    dispatch(previousStep());
  };

  const isButtonDisabled = () => {
    // @ts-ignore
    return (
      // currentStep > 1 && // step 1 should not be restrained
      // @ts-ignore
      !steps[currentStep]?.done
    );
  };

  return (
    <div>
      {getWizardStep({
        step: +currentStep,
        steps, // build flow from defined steps in workflow instead of hardcode
        continueHook,
      })}
      <div className="flex flex-col items-center gap-3">
        {currentStep !== numberSteps && (
          <button
            type="button"
            className={twMerge(
              "group/next mx-auto rounded-sm w-3/4 md:w-96 py-2 text-md font-semibold text-white flex items-center justify-center gap-1 shadow-lg",
              isButtonDisabled() || loading
                ? "bg-gray-400 text-gray-300  cursor-not-allowed"
                : "bg-primary hover:bg-primary/90 animate-pulse",
            )}
            onClick={onNext}
            disabled={isButtonDisabled()}
          >
            Continue
            <GoArrowRight
              className={twMerge(
                !isButtonDisabled() &&
                  "group-hover/next:translate-x-1 transition-all",
              )}
            />
          </button>
        )}
        {currentStep === 1 ||
        currentStep === numberSteps ||
        !canGoBack ? null : (
          <button
            type="button"
            className="text-sm text-gray-400/90 hover:text-gray-600 flex gap-1 items-center"
            onClick={onPrevious}
          >
            <GoChevronLeft />
            Back To Previous Step
          </button>
        )}
      </div>
    </div>
  );
}
