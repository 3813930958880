import dynamic from "next/dynamic";

const Image = dynamic(() => import("next/image"));

export default function Footer() {
  return (
    <footer className="w-full px-6 bg-darkBlue text-white/90 py-5">
      <div className="md:flex sm:block justify-between items-center text-center max-w-7xl mx-auto">
        <Image
          src="/mm_logo_full.webp"
          alt="Mastermind.com logo"
          width={200}
          height={50}
          className="mx-auto block lg:mx-0"
          priority={false}
        />
        <div className="text-sm flex flex-wrap gap-2 justify-center md:justify-end mt-2 md:mt-0">
          <p>
            {new Date().getFullYear()} © Mastermind Consulting LLC - All Rights
            Reserved
          </p>
          <div className="flex gap-2">
            <span className="md:block hidden">|</span>
            <a
              className="hover:text-primary hover:underline"
              href="https://learn.mastermind.com/terms-of-service1692295366124"
              target="_blank"
            >
              Terms & Conditions
            </a>
            <span>|</span>
            <a
              className="hover:text-primary hover:underline"
              href="https://learn.mastermind.com/privacy-policy1692295366382"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
