import { useAppDispatch } from "@/store/hooks";
import { useEffect, useState } from "react";
import {
  setUser,
  setSession,
  setState,
  setSteps,
} from "@/store/features/wizardSlice";
import Header from "@/ui/Header";
import Footer from "@/ui/Footer";
import Wizard from "@/components/Wizard";
import { Montserrat } from "next/font/google";
import Spinner from "@/components/shared/Spinner";

const montserrat = Montserrat({ subsets: ["latin"] });

export default function AppWrapper({
  user,
  session,
  sessionExpired,
  workflow,
}: {
  user: { firstName: string; lastName: string; email: string };
  session: any;
  sessionExpired: boolean;
  workflow: any;
}) {
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (user && session && workflow) {
      localStorage.setItem("onboarding_session", session.uuid);
      dispatch(setUser(user));
      dispatch(setSession(session));
      if (workflow.steps) {
        // console.log("wf has steps", workflow.steps);
        dispatch(setSteps(workflow.steps));
      }
      if (workflow.state) {
        // console.log("wf state", workflow.state);
        dispatch(setState(workflow.state));
      }
    }

    setLoading(false);
  }, [user, session, workflow, dispatch]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="w-full h-full bg-academy bg-no-repeat bg-cover">
      <>
        <main
          className={`flex min-h-[calc(100dvh-131px)] sm:min-h-[calc(100dvh-75px)] flex-col max-w-6xl mx-auto p-6 ${montserrat.className}`}
        >
          {sessionExpired || !user || !user.email ? (
            <div className="text-center">
              <p className="text-lg">
                {sessionExpired
                  ? "Your session has expired"
                  : "You may have landed here on accident..."}
              </p>
              <p className="text-md hover:underline hover:cursor-pointer">
                Contact Sales
              </p>
            </div>
          ) : (
            <>
              <Header />
              <section className="w-full mt-3 mb-10">
                <Wizard user={user} />
              </section>
            </>
          )}
        </main>
        <Footer />
      </>
    </div>
  );
}
